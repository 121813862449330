<template>
  <div
    class="d-flex flex-row-fluid bgi-size-cover bgi-position-center mb-7"
    :style="{ backgroundImage: `url(${backgroundImage})` }"
  >
    <div class="container">
      <div
        class="d-flex position-relative w-100 px-lg-40 m-auto flex-column py-25"
      >
        <!--begin::Heading-->
        <h1
          class="mb-12"
          style="font-size: 36px; text-align: center; color: #FFFFFF;"
        >
          SIDATINKUM
        </h1>
        <h3
          class="text-white mb-12"
          style="font-size: 24px; text-align: center; color: #FFFFFF;"
        >
          Selamat datang di Sistem Data dan Informasi Hukum <br />
          Direktorat Jenderal Politik dan Pemerintahan Umum
        </h3>
        <!--end::Heading-->
        <!--begin::Form-->
        <div class="d-flex position-relative w-100 px-lg-40 m-auto">
          <div
            :class="notFound ? 'input-group invalid-feedback' : 'input-group'"
            id="tooltip-not-found"
          >
            <input
              type="text"
              class="form-control h-auto border-0 pl-7 py-5 px-1 font-size-h6"
              placeholder="Nama Produk Hukum"
              v-model="search"
              @keyup.enter="searchDocument()"
              @keydown.enter.prevent="searchDocument()"
            />
            <div class="input-group-append">
              <button
                class="btn btn-cari"
                type="button"
                id="button-addon2"
                @click="searchDocument"
                :disabled="!disabled"
              >
                <span v-if="!loading"
                  ><span class="svg-icon svg-icon-lg">
                    <inline-svg src="/media/svg/custom/search.svg" />
                  </span>
                </span>
                <b-spinner medium v-else></b-spinner>
              </button>
            </div>
            <!--end::Input-->
          </div>
          <b-tooltip
            :disabled.sync="disabled"
            :show.sync="show"
            target="tooltip-not-found"
            variant="danger"
            placement="bottomleft"
          >
            Pencarian tidak boleh kosong
          </b-tooltip>
        </div>
        <!--end::Form-->
        <!--        <b-alert
          class="mt-3"
          :show="dismissCountDown"
          dismissible
          style="background-color: #FFFDCB; color: black;"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          Pencarian tidak boleh kosong
        </b-alert>-->
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "HeaderBottom",
  data() {
    return {
      search: "",
      loading: false,
      disabled: false,
      notFound: false,
      show: false,
      dismissSecs: 5,
      dismissCountDown: 0
    };
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-12.jpg";
    }
  },
  mounted() {
    this.disabled = true;
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    async searchDocument() {
      let context = this;
      this.loading = true;
      this.notFound = false;
      this.show = false;
      if (this.search === "") {
        this.dismissCountDown = this.dismissSecs;
        this.loading = false;
      } else {
        await this.$store
          .dispatch("searchDocument", this.search)
          .then(res => {
            this.loading = false;
            if (res.data.length === 0) {
              this.notFound = true;
              this.show = true;
            } else {
              this.$router.push({
                name: "ListDokumentasi",
                params: { result: this.search }
              });
            }
          })
          .catch(async function(err) {
            context.loading = false;
            await Swal.fire(
              {
                title: err.message,
                text: "",
                icon: "warning",
                confirmButtonColor: "#063A69",
                confirmButtonText: "Tutup",
                cancelButtonText: false
              },
              function() {
                Swal.close();
              }
            );
          });
      }
    }
  }
};
</script>

<style scoped>
.btn-cari {
  background: #f2cf17;
  border-radius: 5px;
  padding: 8px 18px;
}
</style>
