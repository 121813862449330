<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar" style="background-color: #18328D">
    <!--begin: Quick Actions -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
      ref="dropdown"
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
        >
          <div class="d-flex flex-column text-right pr-3">
            <span
              class="font-size-sm d-none d-md-inline"
              style="font-weight: 600; font-size: 12px; line-height: 18px; text-align: right; color: #FFFFFF;"
              >Halo, {{ user.email }}</span
            >
          </div>
          <span class="symbol symbol-35">
            <span
              class="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"
              >{{ initial }}</span
            >
          </span>
        </div>
      </template>
      <b-dropdown-text
        tag="div"
        class="min-w-md-175px"
        style="background: #FFFFFF; box-shadow: 0 2px 14px rgba(0, 0, 0, 0.15); border-radius: 10px;"
      >
        <div class="d-flex align-items-center mb-6">
          <!--begin::Text-->
          <div class="d-flex flex-column px-6 mt-5">
            <router-link :to="'/profil'" class="mb-5">
              <a
                class="text-dark font-size-lg font-weight-bold cursor-pointer"
                @click="onClick"
              >
                Profil
              </a>
            </router-link>
            <a
              class="text-dark font-size-lg font-weight-bold cursor-pointer"
              @click="onLogout"
            >
              Keluar
            </a>
          </div>
        </div>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Quick Actions -->

    <!--begin: Notifications -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
      disabled
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-primary"
        >
          <!--          <span class="svg-icon svg-icon-xl">
            <inline-svg src="/media/svg/custom/Notif.svg" />
          </span>-->
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import i18nService from "@/core/services/i18n.service.js";
import Swal from "sweetalert2";
import VueJwtDecode from "vue-jwt-decode";

const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-delete",
    cancelButton: "btn btn-save"
  },
  buttonsStyling: false
});

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
      user: {},
      initial: ""
    };
  },
  components: {
    KTDropdownNotification
  },
  methods: {
    getUserDetails() {
      // get token from localstorage
      try {
        let token = localStorage.getItem("token");
        //decode token here and attach to the user object
        this.user = VueJwtDecode.decode(token);
        this.initial = this.user.email.substring(0, 1).toUpperCase();
      } catch (error) {
        // return error in production env
        console.log(error, "error from decoding token");
      }
    },
    onClick() {
      this.$refs.dropdown.hide();
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    onLogout() {
      swalSuccess
        .fire({
          title: "Anda yakin ingin keluar?",
          icon: "warning",
          iconHtml: "?",
          showCancelButton: true,
          confirmButtonColor: "#18328D",
          confirmButtonText: "Keluar",
          cancelButtonText: "Batal"
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("auth/logout")
              .then(() => this.$router.push("/dashboard").catch(() => {}));
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Anda telah keluar.",
              showConfirmButton: false,
              timer: 1500
            });
          }
        });
    }
  },
  created() {
    this.getUserDetails();
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  }
};
</script>
