<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Beranda </span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
      v-if="!currentUser"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Dokumentasi </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu" style="max-width: 300px">
          <ul class="menu-content">
            <li class="menu-item">
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Dokumentasi </span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner p-0">
                <router-link
                  :to="'/dropdown/' + jenis.id"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-for="jenis in jenisPerundangan"
                  :key="jenis.id"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text"> {{ jenis.name }} </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>

    <router-link
      to="/login"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        v-if="!currentUser"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
          'absolute-right'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Login </span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
      :to="{ name: 'TabelDokumentasi' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    > -->
    <!-- <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
        v-if="currentUser"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Dokumentasi </span>
        </a>
      </li> -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ]"
      v-if="currentUser"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Dokumentasi </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu" style="max-width: 300px">
          <ul class="menu-content">
            <li class="menu-item">
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Dokumentasi </span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner p-0">
                <router-link
                  :to="'/dokumentasi/tabel/' + jenis.id"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-for="jenis in jenisPerundangan"
                  :key="jenis.id"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text"> {{ jenis.name }} </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <!-- </router-link> -->

    <router-link
      to="/tabel"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
        v-if="currentUser"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Perundang-undangan </span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{ name: 'TabelAdvokasi' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
        v-if="currentUser"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Advokasi </span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{ name: 'KelembagaanProvinsi' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
        v-if="currentUser"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Kelembagaan Kesbangpol </span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-if="currentUser && showSettings"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Pengaturan </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu" style="max-width: 300px">
          <ul class="menu-content">
            <li class="menu-item">
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Pengaturan </span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner">
                <router-link
                  :to="{ name: 'role' }"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text"> User Management </span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  :to="{ name: 'Pengaturan' }"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text"> Master Data </span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  :to="{ name: 'AuditTrailTable' }"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text"> Audit Trail </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-if="currentUser"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Panduan </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu" style="max-width: 300px">
          <ul class="menu-content">
            <li class="menu-item">
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Panduan </span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner">
                <router-link
                  :to="{ name: 'ManualWeb' }"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text"> Manual Aplikasi Web </span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  :to="{ name: 'ManualAndroid' }"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text"> Manual Aplikasi Android </span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  :to="{ name: 'ManualIos' }"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text"> Manual Aplikasi iOS </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import axios from "axios";

export default {
  name: "KTMenu",
  data() {
    return {
      jenisPerundangan: []
    };
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_URL_LOCAL +
          `/master/jenis-perundangan?skip=0&take=20`
      )
      .then(items => {
        this.jenisPerundangan = items.data.data;
      });
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
  computed: {
    showSettings() {
      return this.$store.state.token.is_superadmin;
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  }
};
</script>
