<template>
  <!-- begin:: Footer -->
  <div
    class="footer kt-grid__item bgi-position-center-center bgi-size-cover bgi-no-cover offcanvas-mobile"
    id="kt_footer"
    style="background-image: url('/media/bg/bg-2.jpg')"
  >
    <!--begin::Container-->
    <div class="container py-lg-18 py-8">
      <!--begin::Row-->
      <div class="row">
        <!--begin::Col-->
        <div class="col-lg-6 my-lg-0 my-5">
          <h6 class="text-white pb-3">
            DIREKTORAT JENDERAL POLITIK DAN <br />PEMERINTAHAN UMUM
          </h6>
          <p class="m-0 text-white opacity-45">
            Jl. Medan Merdeka Utara No. 7, Jakarta Pusat
            <br />
            Telp. (021) 3521535
            <br />
            Email:
            <a
              href="mailto:kesbangpol_depdagri@yahoo.co.id"
              class="text-white opacity-55 text-hover-primary"
              >kesbangpol_depdagri@yahoo.co.id</a
            >
            <br />
            <a
              href="mailto:bagianpuupolpum2017@gmail.com"
              class="text-white opacity-55 text-hover-primary ml-13"
              >bagianpuupolpum2017@gmail.com</a
            >
          </p>
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-lg-6 my-lg-0 my-5">
          <h4 class="text-white pb-3">INFORMASI TERKAIT</h4>
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column">
              <a
                href="https://www.kemendagri.go.id/"
                target="_blank"
                class="text-white opacity-55 text-hover-primary mb-3"
              >
                Website Kemendagri
              </a>
              <a
                href="https://polpum.kemendagri.go.id/"
                target="_blank"
                class="text-white opacity-55 text-hover-primary mb-3"
              >
                Website Ditjen Politik dan Pemerintahan Umum
              </a>
              <a
                href="https://peraturan.go.id/"
                target="_blank"
                class="text-white opacity-55 text-hover-primary mb-3"
              >
                Website Peraturan.go.id
              </a>
              <a
                href="https://kepaniteraan.mahkamahagung.go.id/perkara/"
                target="_blank"
                class="text-white opacity-55 text-hover-primary mb-3"
              >
                Informasi Perkara Mahkamah Agung
              </a>
              <a
                href="https://mkri.id/index.php?page=web.Putusan&id=1&kat=1&menu=5"
                target="_blank"
                class="text-white opacity-55 text-hover-primary"
              >
                Mahkamah Konstitusi Republik Indonesia
              </a>
            </div>
            <!--            <div class="d-flex flex-column">
              
            </div>-->
          </div>
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <!--        <div class="col-lg-4 my-lg-0 my-5">
          <h4 class="text-white pb-3">KRITIK & SARAN</h4>
          <form class="rounded" style="background-color: rgba(0,0,0,.2);">
            <div class="input-group p-2 align-items-center">
              <input
                type="text"
                class="form-control rounded-right border-0 bg-transparent text-white opacity-80"
                placeholder="Masukkan email Anda"
              />
              <div class="input-group-append p-0 mr-1">
                <button
                  class="btn btn-fh btn-sm btn-primary px-6 rounded-left"
                  type="button"
                >
                  Kirim
                </button>
              </div>
            </div>
          </form>
        </div>-->
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
    <!--end::Container-->
    <!--begin::Container-->
    <div class="separator separator-solid opacity-7"></div>
    <!--end::Container-->
    <!--begin::Container-->
    <div class="container py-8">
      <div
        class="d-flex align-items-center justify-content-between flex-lg-row flex-column"
      >
        <!--begin::Copyright-->
        <div class="d-flex align-items-center order-lg-1 order-2">
          <a class="text-white opacity-55 pl-0 text-hover-primary">
            version: 20240101.1
          </a>
        </div>
        <!--end::Copyright-->
        <!--begin::Nav-->
        <div class="d-flex align-items-center order-lg-2 order-1 mb-lg-0 mb-5">
          <span class="text-muted font-weight-bold mx-2">© Ditjen Polpum</span>
          <a
            href="https://polpum.kemendagri.go.id/"
            target="_blank"
            class="text-primary text-hover-primary"
          >
            Kementerian Dalam Negeri Republik Indonesia
          </a>
        </div>
        <!--end::Nav-->
      </div>
    </div>
    <!--end::Container-->
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
};
</script>
